import {
  Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControl, FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import React, { useEffect } from 'react';

import {
  closeServiceDialogAddEdit,
  fetchServices,
  ServicesPageState, setImage, setServiceHidden,
  setServiceInputDescription, setServiceInputGroupId,
  setServiceInputName
} from '../servicesPage.slice';
import { RootState } from '../../../store/store';
import { openError, toggleDialogInfo } from '../../../components/dialog/dialogInfo.slice';
import servicesService from '../../../services/services/services.service';
import { ServiceGroup } from '../../../services/serviceGroups/serviceGroups.types';
import { getServiceGroups } from '../../../store/dictionaries.selectors';
import { fetchServiceGroupsDict } from '../../../store/dictionaries.slice';
import validateForm from '../../../utils/validation/validateForm';
import { dataURItoBlob } from '../../../utils/dataUriToBlob';
import UploadImage from '../../../components/UploadImage';

export const ServiceDialogAddEdit = () => {
  const dispatch: ThunkDispatch<ServicesPageState, any, AnyAction> = useDispatch();

  const {
    inputs: { description, name, id, groupId, hidden, photo },
    mode,
    open: openDialogAddEdit,
  } = useSelector((state: RootState) => state.services.services.dialogs.addEdit);
  const { data: services } = useSelector((state: RootState) => state.services.services);

  const serviceGroups = useSelector(getServiceGroups);

  const dialogClose = () => dispatch(closeServiceDialogAddEdit(0));

  const removeImage = () => { dispatch(setImage('')) };

  useEffect(() => {
    if (serviceGroups.length === 0) {
      dispatch(fetchServiceGroupsDict());
    }
  }, [dispatch, serviceGroups.length]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>, mode: 'add' | 'edit') => {
    if (!validateForm(e)) return;

    if (groupId === '') { dispatch(openError('Group is incorrect')); return; }

    let textAction, textActionPast;
    const body = { name, description, serviceGroupIds: [groupId], hidden };

    try {
      let serviceId: number;
      if (mode === 'add') {
        [textAction, textActionPast] = ['add', 'added'];

        const newService = await servicesService.add(body);
        serviceId = newService.id;
      } else {
        if (!id) { dispatch(openError('ID is undefined')); return; }

        [textAction, textActionPast] = ['edit', 'edited'];

        await servicesService.edit(id, body);
        serviceId = id;
      }

      if (photo?.includes('data:image')) {
        const file = dataURItoBlob(photo);
        await servicesService.uploadImage(serviceId, file);
      } else if (!photo) {
        const currentService = services.find((service) => service.id === id);
        if (currentService && currentService.photo) {
          await servicesService.deleteImage(serviceId);
        }
      }

      dispatch(fetchServices());
      dialogClose();

      dispatch(toggleDialogInfo({
        open: true,
        type: 'info',
        message: `Successfully ${textActionPast}.`,
      }));

      setTimeout(() => {
        dispatch(toggleDialogInfo({ open: false }));
      }, 3000);
    } catch (error: any) {
      dispatch(toggleDialogInfo({
        open: true,
        type: 'error',
        message: `Error while ${textAction} service: ${error.message}`,
      }));
    }
  };

  return (
    <Dialog open={openDialogAddEdit} onClose={dialogClose}>
      <form onSubmit={(e) => handleSubmit(e, mode)}>
        <DialogTitle>{mode === 'add' ? 'Add service' : 'Edit service'}</DialogTitle>

        <DialogContent>
          <FormControl sx={{width: 500}}>
            <InputLabel id="group-label">Group *</InputLabel>
            <Select
              labelId="group-label"
              fullWidth
              value={groupId}
              onChange={(e) => dispatch(setServiceInputGroupId(e.target.value))}
              required={true}
            >
              {serviceGroups.map((group: ServiceGroup) => (
                <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
              ))}
            </Select>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              value={name}
              onChange={(e) => dispatch(setServiceInputName(e.target.value))}
              sx={{marginBottom: 1, marginTop: 1}}
              required={true}
            />
            <TextField
              multiline={true}
              rows={6}
              label="Description"
              variant="outlined"
              fullWidth
              onChange={(e) => dispatch(setServiceInputDescription(e.target.value))}
              value={description}
              sx={{ marginTop: 1 }}
              required={true}
            />
            <FormControlLabel control={<Checkbox
              checked={!hidden}
              onChange={(e) => dispatch(setServiceHidden(!e.target.checked))}
            />} label="Visible service"/>
          </FormControl>

          <UploadImage image={photo} setImage={setImage} removeImage={removeImage}/>
        </DialogContent>

        <DialogActions>
          <Button onClick={dialogClose}>Cancel</Button>
          <Button type="submit" variant="contained" color="primary">{mode === 'edit' ? 'Save Changes' : 'Add service'}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
